import { makeStyles } from "@material-ui/styles";
import { ReactComponent as Icon1 } from "../../assets/images/docs.svg";
import { ReactComponent as Icon2 } from "../../assets/images/github.svg";
import { ReactComponent as Icon3 } from "../../assets/images/twitter.svg";
import { ReactComponent as Icon4 } from "../../assets/images/medium.svg";
import { ReactComponent as Icon5 } from "../../assets/images/discord.svg";

const useStyle = makeStyles({
  footer: {
    padding: "40px 0",
    "& .content": {
      padding: "0 10px",
      textAlign: "left",
      maxWidth: 1234,
      width: "100%",
      margin: "0 auto",
    },
  },
  icon: {
    marginRight: 24,
    cursor: "pointer",
    "&:hover path": {
      fill: "#C62A1D",
    },
  },
});

const ICON = [
  {
    icon: Icon1,
  },
  {
    icon: Icon2,
  },
  {
    icon: Icon3,
  },
  {
    icon: Icon4,
  },
  {
    icon: Icon5,
  },
];
export function Footer() {
  const classes = useStyle();
  return (
    <div className={classes.footer}>
      <div className="content">
        {ICON.map((icon, i) => {
          return <icon.icon className={classes.icon} key={i} />;
        })}
      </div>
    </div>
  );
}
