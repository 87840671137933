import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";

const RODOMAP = [
  {
    title: "SET UP",
    desc: "2021 / 03 / 21",
  },
  {
    title: "Release swap",
    desc: "2021 / 06",
  },
  {
    title: "NFT MArket",
    desc: "2021 / 08",
  },
  {
    title: "Bull Chain",
    desc: "2021 / 12",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 650,
    width: "100%",
    padding: 0,
    margin: "60px auto 0",
    textAlign: "left",
  },
  content: {
    border: "2px solid rgba(198,42,29,.3)",
    borderRadius: 2,
    padding: 10,
    "& .title": {
      fontSize: 24,
    },
    "& .desc": {
      fontSize: 16,
    },
  },
}));

export default function CustomizedTimeline() {
  const classes = useStyles();

  return (
    <Timeline align="alternate" className={classes.root}>
      {RODOMAP.map((d) => {
        return (
          <TimelineItem key={d.title}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className={classes.content}>
                <Typography className="title" variant="h6" component="h1">
                  {d.title}
                </Typography>
                <Typography className="desc">{d.desc}</Typography>
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
