import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import banner from "./images/banner.png";
import { Logo } from "../../components/Logo";
import CustomizedTimeline from "./TimeLine";
import { useMedia } from "../../hooks/useMedia";
const bannerNFT = require("./images/group-left.png").default;
const useStyle = makeStyles({
  banner: {
    padding: "0 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "url(" + banner + ")no-repeat center/1234px auto",
  },
  title: {
    maxWidth: 564,
    width: "80vw",
    fontWeight: 700,
    fontSize: (d) => {
      return d ? 40 : 80;
    },
    textAlign: "center",
    fontFamily: "DINProBlack",
    textTransform: "uppercase",
  },
  btn: {
    marginTop: 88,
    width: 180,
    height: 48,
    fontSize: 20,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#C62A1D",
    borderRadius: 2,
    cursor: "pointer",
  },
  desc: {
    fontSize: 24,
    lineHeight: "28px",
    textAlign: "center",
  },
  row: {
    maxWidth: 1234,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  info: {
    marginTop: 100,
    backgroundColor: "#1F1F1F",
    "& .item": {
      height: 243,
      padding: "0 10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#000000",
    },
  },
  marginTop100: {
    marginTop: 100,
  },
  project: {
    fontSize: 48,
  },
  nft: {
    backgroundColor: "rgba(31,31,31,.8)",
    padding: 38,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: 480,
    position: "relative",
    overflow: "hidden",
    "& .left-banner,& .right-banner": {
      height: 348,
      position: "absolute",
      top: "50%",
    },
    "& .left-banner": {
      left: "50%",
      transform: "translate(10px,-50%)",
    },
    "& .right-banner": {
      left: "50%",
      transform: "translate(calc(-100% - 10px),-50%)",
    },
  },
  us: {
    paddingTop: 100,
    "& .item": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
      "& .detail": {
        color: "rgba(255,255,255,.6)",
      },
    },
  },
  partner: {
    marginTop: 50,
    padding: "100px 0",
    backgroundColor: "rgba(31,31,31,.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
});

const INFO = [
  {
    title: "Bull Swap",
    desc: "Exchange, Farm, Stake, IDO",
    icon: require("./images/icon1.svg"),
  },
  {
    title: "Bull NFT",
    desc: "NFT exchange, Stake, Customization",
    icon: require("./images/icon2.svg"),
  },
  {
    title: "Bull chain",
    desc: "Focus on Financial service, Efficient,Convenient",
    icon: require("./images/icon3.svg"),
  },
];

const US = [
  {
    title: "Frank Grilio",
    desc: "CEO, CO-Founder",
    detail:
      "Former vice president of Investment Department of JPMorgan Chase Bank, with rich experience in banking services and talent management.",
  },
  {
    title: "Yanagawa Takeshi",
    desc: "COO, CO-FOUNDER",
    detail:
      "Director of public relations, Sony media, has a wealth of media industry resources.",
  },
  {
    title: "MA LAO BAN",
    desc: "CTO, CO-FOUNDER",
    detail:
      "Former technical director of a famous exchange. More than 10 years of technical management experience.",
  },
  {
    title: "MA LAO BAN",
    desc: "CMO",
    detail: "Former CMO of a famous exchange.",
  },
];

const PARTNER = [
  require("./images/partner7.svg").default,
  require("./images/partner6.svg").default,
  require("./images/partner8.svg").default,
  require("./images/partner9.svg").default,
  require("./images/partner1.svg").default,
  require("./images/partner2.svg").default,
  require("./images/partner3.svg").default,
  require("./images/partner4.svg").default,
  require("./images/partner5.svg").default,
];

const Content = ({ title, desc, children }) => {
  const classes = useStyle();
  return (
    <>
      <div className={clsx(classes.project, "title")}>{title}</div>
      <img
        src={require("./images/arrow.svg").default}
        alt=""
        style={{ marginTop: 10 }}
      />
      <div className="desc" style={{ marginTop: 20 }}>
        {desc}
      </div>
      {children}
    </>
  );
};

export function Home() {
  const matched = useMedia();
  const classes = useStyle(matched);

  return (
    <div className={classes.home}>
      <div className={classes.banner}>
        <div className={clsx(classes.title, "title")}>
          The Creative Financial Service
        </div>
        <div className="title" style={{ marginBottom: 30 }}>
          BUS COIN
        </div>
        <Logo style={{ marginBottom: 50 }} />
        <div
          className={clsx("desc", classes.desc)}
          style={{ marginBottom: 12 }}
        >
          Born in a bull market, with swap and NFT.
        </div>
        <div className={clsx("desc", classes.desc)}>
          The Token named bullcoin(BUS) will used for the whole ecology.
        </div>
        <div className={classes.btn}>Get Bull Coin</div>
      </div>
      <div className={classes.info}>
        <Grid className={classes.row} container spacing={5}>
          {INFO.map((d, i) => {
            return (
              <Grid item xs={12} sm={4} key={i}>
                <div className="item">
                  <img
                    src={d.icon.default}
                    alt=""
                    style={{ marginBottom: 30 }}
                  />
                  <div
                    className={clsx("title")}
                    style={{ fontSize: 24, marginBottom: 20 }}
                  >
                    {d.title}
                  </div>
                  <div
                    className={clsx(classes.desc, "desc")}
                    style={{ fontSize: 16 }}
                  >
                    {d.desc}
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <div className={classes.marginTop100}>
        <Content
          title="BullSwap"
          desc="Designed for efficient exchange requirement"
        >
          <img
            src={require("./images/banner-pc.png").default}
            alt=""
            style={{ marginTop: 100, maxWidth: 1211, width: "80%" }}
          />
        </Content>
      </div>
      <div className={clsx(classes.nft)}>
        <img className="left-banner" src={bannerNFT} alt="" />
        <img className="right-banner" src={bannerNFT} alt="" />
        <Content
          title="BullNFT"
          desc=" Unprecendent ways to enjoy NFT"
        ></Content>
      </div>
      <div className={classes.marginTop100}>
        <Content
          title="RoadMAP"
          desc="Our development path and subsequent planning"
        >
          <CustomizedTimeline />
        </Content>
      </div>
      <div className={classes.us}>
        <Content title="ABout US" desc="Our Team">
          <Grid
            container
            spacing={10}
            style={{ margin: "60px auto 0", maxWidth: 1234, width: "100%" }}
          >
            {US.map((d, i) => {
              return (
                <Grid item xs={12} sm={3} key={i}>
                  <div className="item">
                    <div
                      className={clsx("title")}
                      style={{ marginBottom: 10, fontSize: 24 }}
                    >
                      {d.title}
                    </div>
                    <div
                      className={clsx("desc")}
                      style={{ fontSize: 16, marginBottom: 10 }}
                    >
                      {d.desc}
                    </div>
                    <div
                      className="detail"
                      style={{ fontSize: 14, lineHeight: "17px" }}
                    >
                      {d.detail}
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Content>
      </div>
      {/* <div className={classes.partner}>
        <Content title="Our Partner">
          <div style={{ width: "100%" }}>
            <Grid
              container
              spacing={4}
              className={classes.row}
              style={{ maxWidth: 800, marginTop: 40 }}
            >
              {PARTNER.map((img, i) => {
                return (
                  <Grid item xs={6} md key={i}>
                    <div
                      style={{
                        width: "100%",
                        height: 40,
                        maxWidth: 180,
                        minWidth: 120,
                        background: `url(${img})no-repeat center/contain`,
                      }}
                    ></div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Content>
      </div> */}
    </div>
  );
}
