import { useState } from "react";
import { ReactComponent as NavIcon } from "./images/nav.svg";
import { Drawer, List, ListItem, ListItemText, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Logo } from "../Logo";
import { useHistory } from "react-router";
import { useMedia } from "../../hooks/useMedia";
const useStyle = makeStyles({
  header: {
    width: "100%",
    margin: "0 auto",
    maxWidth: 1234,
    padding: 17,
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  nav: {
    fontWeight: "bold",
    fontSize: 16,
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    color: "rgba(255,255,255,.3)",
    textDecoration: "none",
    "&:hover,&.active": {
      color: "#C62A1D",
    },
  },
});

const Nav = [
  {
    name: "HOME",
    path: "/",
  },
  {
    name: "SWAP",
    path: "#",
  },
  {
    name: "NFT",
    path: "#",
  },
  {
    name: "ROADMAP",
    path: "#",
  },
  {
    name: "PARTNER",
    path: "#",
  },
  {
    name: "CONTACT",
    path: "#",
  },
];

export function Header() {
  const classes = useStyle();
  // const history = useHistory();
  const [state, setState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };
  const matched = useMedia();
  return (
    <>
      <div className={classes.header}>
        <Logo
          style={{
            width: 28,
            position: "absolute",
            left: 10,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
        <Grid
          container
          justify={matched ? "flex-end" : "center"}
          wrap="nowrap"
          spacing={5}
        >
          {matched ? (
            <Grid item onClick={toggleDrawer(true)}>
              <NavIcon width={30} height={30} />
            </Grid>
          ) : (
            Nav.map((d) => {
              return (
                <Grid key={d.name} item>
                  <a
                    className={clsx(classes.nav, { active: d.path === "/" })}
                    href="/"
                    onClick={function (e) {
                      e.preventDefault();
                      if (d.path !== "/") {
                        alert("coming soon...");
                      }
                    }}
                  >
                    {d.name}
                  </a>
                </Grid>
              );
            })
          )}
        </Grid>
      </div>
      <Drawer anchor="top" open={state} onClose={toggleDrawer(false)}>
        <List>
          {Nav.map((d, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                if (d.path !== "/") {
                  alert("coming soon...");
                }
                setState(false);
              }}
            >
              <ListItemText primary={d.name} style={{ textAlign: "center" }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}
